<template>
  <div class="adminMain">
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right" class="mB20">
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/adminList' }">管理员管理</el-breadcrumb-item>
      <el-breadcrumb-item v-if="!id">新建管理员</el-breadcrumb-item>
      <el-breadcrumb-item v-if="id">编辑管理员</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="hengBox"></div> -->

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
      <el-form-item label="账号" prop="username">
        <el-input v-model="ruleForm.username" placeholder="请输入账号"></el-input>
        <span class="tips">必填，需由6-12位英文/数字/字符组成，区分大小写</span>
      </el-form-item>
      <el-form-item v-if="!id" label="密码" prop="password">
        <el-input v-model="ruleForm.password" placeholder="请输入密码"></el-input>
        <span class="tips">必填，需由6-18位英文/数字/字符组成，区分大小写</span>
      </el-form-item>
      <el-form-item label="姓名" prop="realname">
        <el-input v-model="ruleForm.realname" placeholder="请输入姓名"></el-input>
        <span class="tips">不超过10个汉字</span>
      </el-form-item>
      <div>
        <div class="el-form-item__label requiredTip box center horizontal"><span>单位</span></div>
        <el-form-item class="companyItem" prop="departmentIds">
          <el-cascader v-model="compayArrId" :options="companyList" :props="{ multiple: true, checkStrictly: true, emitPath: false, value: 'id', label: 'name' }" :show-all-levels="false" clearable></el-cascader>
        </el-form-item>
      </div>
      <el-form-item label="角色" prop="roleIds">
        <!-- <el-checkbox-group v-model="roleArrId">
          <el-checkbox v-for="item in roleList" :key="item.id" :label="item.id + ''">{{ item.name }}</el-checkbox>
        </el-checkbox-group> -->
        <el-radio-group v-model="roleArrId">
          <el-radio v-for="item in roleList" :key="item.id" :label="item.id + ''">{{ item.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div class="box center horizontal mT70">
      <!-- <el-button @click="save">保存</el-button>
        <el-button class="restBtn" @click="returnPage">返回</el-button> -->
      <div class="btnSelect1 mR20 pointer" @click="returnPage">返回</div>
      <div class="btnSelect2 pointer" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    var checkData = (rule, value, callback) => {
      if (value) {
        if (/[\u4E00-\u9FA5]/g.test(value)) {
          callback(new Error("编码不能输入汉字!"));
        } else {
          callback();
        }
      }
      callback();
    };
    return {
      rules: {
        username: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { validator: checkData, min: 6, max: 12, message: "6-12位英文/数字/字符组成", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { validator: checkData, min: 6, max: 18, message: "6-18位英文/数字/字符组成", trigger: "blur" }
        ],
        realname: [
          { required: true, message: "请输入姓名", trigger: "blur" },
          { min: 0, max: 10, message: "不超过10个汉字", trigger: "blur" }
        ],
        departmentIds: [{ required: true, message: "请选择单位", trigger: "blur" }]
      },
      ruleForm: { username: "", password: "", realname: "", departmentIds: "", roleIds: "" },
      companyList: [],
      roleList: [],
      checkedList: [],
      compayArrId: [],
      roleArrId: '',
      id: ""
    };
  },
  mounted() {
    this.getCompanyList();
    this.getRoleList();
    this.id = this.$route.query.id;
    if (this.id) {
      this.getUserDetail();
      let arr = this.$store.state.breadcrumbList;
      arr.splice(2);
      arr.push({ name: "编辑管理员" });
      this.$store.commit("getBreadcrumb", arr);
    } else {
      let arr = this.$store.state.breadcrumbList;
      arr.splice(2);
      arr.push({ name: "新建管理员" });
      this.$store.commit("getBreadcrumb", arr);
    }
  },
  beforeDestroy() {
    let arr = this.$store.state.breadcrumbList;
    arr.splice(2);
    this.$store.commit("getBreadcrumb", arr);
  },
  methods: {
    save() {
      this.rules.departmentIds[0].required = this.compayArrId.length > 0 ? false : true;
      if (this.id) this.rules.password[0].required = false;
      this.$refs.ruleForm.validate((valid) => {
        console.log(valid);
        if (valid) {
          // let roleArrId = this.roleArrId;
          // let ids = "";
          // for (let item of roleArrId) {
          //   ids += item + ",";
          // }
          // this.ruleForm.roleIds = ids.substring(0, ids.length - 1);
          this.ruleForm.roleIds = this.roleArrId
          let companyArr = this.compayArrId;
          let str = "";
          for (let item of companyArr) {
            str += item + ",";
          }
          this.ruleForm.departmentIds = str.substring(0, str.length - 1);
          if (!this.id) {
            this.httpAddPro();
          } else {
            this.httpEditPro();
          }
        }
      });
    },
    clearData() {
      this.compayArrId = [];
    },
    returnPage() {
      this.clearData();
      this.$router.go(-1);
      this.$refs.ruleForm.resetFields();
    },
    httpAddPro() {
      const params = this.ruleForm;
      // params.password = this.$md5(params.password)    //去掉md5的加密
      this.$axios.post(`${this.baseURL}auth/authUser/`, this.$qs.stringify(params)).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          this.returnPage();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    httpEditPro() {
      this.$axios.put(`${this.baseURL}auth/authUser/`, this.$qs.stringify(this.ruleForm)).then((res) => {
        res = res.data;
        if (res.errCode == 200) {
          /*this.$message({
            message: '保存成功',
            type: 'success'
          });*/
          this.returnPage();
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 获取用户信息
    getUserDetail() {
      this.$axios.get(`${this.baseURL}auth/authUser/${this.id}`).then((res) => {
        res = res.data;
        if (res.errCode === "200") {
          this.ruleForm = res.data;
          this.compayArrId = !res.data.departmentId ? [] : res.data.departmentId.split(",");
          this.roleArrId = res.data.roleIds;
        }
      });
    },
    // 获取单位
    getCompanyList() {
      this.$axios.get(`${this.baseURL}base/baseDepartment/user/department`).then((res) => {
        res = res.data;
        if (res.errCode === "200") this.companyList = res.data[0].children;
      });
    },
    // 获取角色
    getRoleList() {
      this.$axios.get(`${this.baseURL}auth/authRole/all/user`).then((res) => {
        res = res.data;
        if (res.errCode === "200") this.roleList = res.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-form {
  background: #071a42;
  // border: 1px solid #5E5E5F;
  // box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5);
  padding: 21px 0;
  .el-form-item {
    display: flex;
    align-items: center;
    .el-form-item__label {
      width: 148px;
      height: 54px;
      background: #0c265a;
      // border-radius: 0px 0px 0px 8px;
      padding: 0;
      text-align: center;
      line-height: 54px;
    }
    .el-form-item__content {
      padding-left: 46px;
      .el-input {
        width: 178px;
        height: 32px;
        .el-input__icon {
          line-height: 32px;
        }
      }
      .el-checkbox__inner {
        width: 20px;
        height: 20px;
        &:after {
          height: 9px;
          left: 6px;
          width: 5px;
        }
      }
      .el-form-item__error {
        left: 48px;
      }
      .tips {
        color: #f0f0f0;
        padding-left: 30px;
      }
    }
  }
  .companyItem {
    .el-form-item__content {
      width: 50%;
      .el-input {
        width: 100%;
      }
    }
  }
  .el-form-item__label {
    width: 148px;
    height: 100%;
    background: #0c265a;
    // border-radius: 0px 0px 0px 8px;
    padding: 0;
    text-align: center;
    line-height: 52px;
    &.requiredTip:before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
.mT70 {
  margin-top: 70px;
}
.restBtn {
  width: 129px;
  height: 40px;
  background: #1e2529;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-left: 60px;
  &:hover {
    background: #1e2529;
  }
}

/* ::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  transform: translate(-45%,-45%) scale(1.5);
} */
::v-deep .el-radio__input.is-checked .el-radio__inner {
  background-color: #193F7F;
}
::v-deep .el-radio__inner::after {
  width: 6px;
  height: 6px;
  background-color: #2772F0;
}
::v-deep .el-radio__inner {
  border: 1px solid #2772F0;
  background-color: #193F7F;
}
::v-deep .el-radio__label {
  color: #fff;
}
</style>
